import styled, { DefaultTheme } from 'styled-components/macro';

export const SlaContainer = styled.div<{ isPause: boolean; isOverdue: boolean }>`
  display: flex;
  color: ${({ theme, isPause, isOverdue }) => getColor(theme, isPause, isOverdue)};
  span {
    color: ${({ theme, isPause, isOverdue }) => getColor(theme, isPause, isOverdue)} !important;
    margin-left: 4px;
  }
`;

function getColor(theme: DefaultTheme, isPause: boolean, isOverdue: boolean) {
  if (isPause) {
    return '#9E9E9E';
  }
  return isOverdue ? theme.RED : theme.BLUE;
}
