import React, { useCallback, useMemo, useRef } from 'react';
import { Container, UserDetail, UserName, TicketNumber, ActionBox, ComposerContainer } from './style';
import Avatar from '@components/Avatar';
import { useTicketManager } from '@providers/TicketManagerProvider';
import { LoadingWrap } from '@components/Loading/style';
import LoadingCircle from '@components/Loading';
import { Link, Redirect, useHistory } from 'react-router-dom';
import MessageBoxProvider from '@providers/MessageBoxProvider';
import MessageBox from './MessageBox';
import { getQueryString } from '@utils/getQueryString';
import Composer from './Composer';
import { useMember } from '@providers/MemberProvider';
import { useTheme } from 'styled-components/macro';
import { TopbarBox } from '@pages/components/TopbarBox/style';
import AbstractBottomModal from '@components/BottomModal/AbstractBottomModal';
import AssineeTab from './AssigneeTab';
import ConfirmSelect from './ConfirmSelect';
import { MOBILE_ROUTE } from '@configs/constants';
import { Icon, Font14G1W600, Font10G5W600 } from '@components/UtilsComponent';
import ResolveDrawer from './ResolveDrawer';
import {
  faSpinnerThird,
  faArrowLeft as fasArrowLeft,
  faCheck as fasCheck,
  faExpandAlt,
  faCompressAlt,
  faPauseCircle,
} from '@fortawesome/pro-solid-svg-icons';
import { useSetting } from '@providers/SettingProvider';
import { IconMove } from '@components/IconMove';
import { canDoTeamFollowUp } from '@utils/followUp';
import { useFollowUp } from '@hooks/useFollowUp';
import { updateFollowUp, updateTeamFollowUp } from '@api/livechat/EngagementMessage';
import { faStar as fasStar, faFlag as fasFlag } from '@fortawesome/pro-solid-svg-icons';
import { faStar as farStar, faFlag as farFlag } from '@fortawesome/pro-regular-svg-icons';
import { useQueryParams } from '@hooks/useQueryParams';
import useTranslation from '@hooks/useTranslation';
import { SelectBtn } from '@components/SelectBtn';
import { GhostButton, PrimaryButton } from '@components/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReplyMessageProvider } from '@providers/ReplyMessageProvider';
import { ITicket } from '@types';
import { SlaText } from '@components/SlaText';
import { isNil } from 'lodash';

const EngagementMessage: React.FC = () => {
  const sheetRefHeight = useRef<number | undefined>();
  const theme: any = useTheme();
  const history = useHistory();
  const { member } = useMember();
  const messageTime = getQueryString(history.location.search, 'messageTime');
  const { setting, permission } = useSetting();
  const [expand, setExpand] = React.useState(false);
  const {
    current,
    canResolveTicket,
    handleInstanceResolve,
    handleSelectAssignee,
    handleCloseAssigneeModal,
    handleTabChange,
    handleSelectTeam,
    handleSelectMember,
    handleCancelConfirm,
    handleConfirmAssignee,
    handleOpenResolveDrawer,
    canChat,
  } = useTicketManager();

  const { context } = current;
  const isTicketOpen = context.ticket?.status === 'open';
  const isSelectingAssignee = current.matches('idle.selectingAssignee');
  const isConfirmAssignee = current.matches('idle.selectingAssignee.confirmSelectAssignee');
  const isAssignFailed = current.matches('idle.selectingAssignee.assignFailed');
  const isFechingTicket = current.matches('fetchingTicket');
  const isAssignSuccess = current.matches('idle.selectingAssignee.assignSuccess');
  const query = useQueryParams();
  const prevTab = query.get('prev');
  const getMessageTime = (time: string | null) => {
    if (!time) {
      return null;
    }
    const convertTime = Number(time);
    if (Number.isNaN(convertTime)) {
      return null;
    }
    return convertTime;
  };

  const [followUp, _followUpMutation, onFollowUp] = useFollowUp({
    ticket: current.context.ticket,
    member,
    updateFollowUpFn: updateFollowUp,
    initCondition: (ticket, member, setter) => {
      const isFollowUp = ticket?.followUps?.some((item: string) => item === member._id);
      if (isFollowUp) {
        setter(true);
        return true;
      }
      return false;
    },
  });
  const [teamFollowUp, _teamFollowUpMutation, onTeamFollowUp] = useFollowUp({
    ticket: current.context.ticket,
    member,
    updateFollowUpFn: updateTeamFollowUp,
    initCondition: (ticket, _member, setter) => {
      if (ticket.isTeamFollowUp) {
        setter(true);
        return true;
      }
      return false;
    },
  });

  const userName = useMemo(() => {
    if (context?.ticket?.userInfo?.displayName) {
      return context?.ticket?.userInfo?.displayName;
    }

    return context?.ticket?.userInfo?.name;
  }, [context.ticket]);

  const canNotDoTeamFollowUp = useMemo(() => {
    // ticket is open
    if (isTicketOpen) return true;
    if (permission.canTeamFollowUp) return false;
    if (!current.context.ticket || !member) return false;
    return !canDoTeamFollowUp(current.context.ticket, member);
  }, [member, current.context.ticket]);
  const _renderSheetContent = () => {
    if (current.hasTag('confirmSelectAssignee')) {
      return (
        <ConfirmSelect
          ticket={context.ticket!}
          height={sheetRefHeight.current}
          handleBack={handleCancelConfirm}
          handleConfirmAssignee={handleConfirmAssignee}
          isLoading={isConfirmAssignee}
          isError={isAssignFailed}
        />
      );
    }
    return (
      <AssineeTab
        handleOnDismiss={handleCloseAssigneeModal}
        height={sheetRefHeight.current}
        members={context.members}
        teams={context.teams}
        handleTabChange={handleTabChange}
        handleSelectTeam={handleSelectTeam}
        handleSelectMember={handleSelectMember}
        isLoading={current.hasTag('fetching-assignee')}
      />
    );
  };

  if (isAssignSuccess) {
    return <Redirect to={MOBILE_ROUTE.RECENTS} />;
  }

  if (isFechingTicket && !context.ticket) {
    return (
      <Container>
        <LoadingWrap>
          <LoadingCircle />
        </LoadingWrap>
      </Container>
    );
  }
  return (
    <Container>
      {expand ? (
        <div className="p-[12px]">
          <AddtionBlock
            selectedTicket={context.ticket!}
            followUpHandler={{
              followUp,
              onFollowUp,
            }}
            teamFollowUpHanlder={{
              teamFollowUp,
              onTeamFollowUp,
            }}
            canNotDoTeamFollowUp={canNotDoTeamFollowUp}
            assignedName={context.ticket?.agentDisplayName}
            canChat={canChat()}
            handleSelectAssignee={handleSelectAssignee}
            resolveHandler={{
              resolving: current.matches('idle.resolvingTicket'),
              canResolveTicketNoWrapUp: canResolveTicket && !setting?.isWrapUpTicketRequired,
              canResolveTicketWrapUp: canResolveTicket && setting?.isWrapUpTicketRequired,
              handleInstanceResolve: handleInstanceResolve,
              handleOpenResolveDrawer: handleOpenResolveDrawer,
            }}
          />
        </div>
      ) : null}

      <TopbarBox>
        <UserDetail>
          <Link to={`/m/recents?status=${prevTab}`}>
            <Icon icon={fasArrowLeft} />
          </Link>
          <div>
            <Avatar width={32} height={32} img={context?.ticket?.userInfo?.image ?? ''} />
          </div>
          <Link to={`/m/user/${context.ticket?.userId}`}>
            <div className="overflow-ellipsis  w-[calc(100vw-260px)]">
              <UserName ellipsis={true}>{userName ?? ''}</UserName>
            </div>
            <div>
              <TicketNumber>#{context?.ticket?.ticketNumber ?? ''}</TicketNumber>
            </div>
          </Link>
        </UserDetail>

        <ActionBox className="space-x-5">
          {/* <IconMove
            isActive={followUp}
            isLoading={false}
            activeIcon={fasStar}
            inactiveIcon={farStar}
            activeColor={'#FFB400'}
            inActiveColor={theme.GREY_5}
            onClick={onFollowUp}
          />
          <IconMove
            isActive={teamFollowUp}
            isLoading={false}
            activeIcon={fasFlag}
            inactiveIcon={farFlag}
            activeColor={'#f72c40'}
            inActiveColor={theme.GREY_5}
            isDisabled={canNotDoTeamFollowUp}
            onClick={onTeamFollowUp}
          /> */}

          {!expand && (
            <ResolveBtn
              resolving={current.matches('idle.resolvingTicket')}
              showIcon
              canResolveTicketNoWrapUp={canResolveTicket && !setting?.isWrapUpTicketRequired}
              canResolveTicketWrapUp={canResolveTicket && setting?.isWrapUpTicketRequired}
              handleInstanceResolve={handleInstanceResolve}
              handleOpenResolveDrawer={handleOpenResolveDrawer}
            />
          )}
          {expand ? (
            <GhostButton
              className="text-[#757575] !w-[36px] !p-2 !flex !justify-center !items-center"
              icon={<FontAwesomeIcon icon={faCompressAlt} />}
              onClick={() => setExpand(false)}
            />
          ) : (
            <GhostButton
              className="text-[#757575] !w-[36px] !p-2 !flex !justify-center !items-center"
              icon={<FontAwesomeIcon icon={faExpandAlt} />}
              onClick={() => setExpand(true)}
            />
          )}
        </ActionBox>
      </TopbarBox>
      <ReplyMessageProvider>
        <MessageBoxProvider
          ticket={context.ticket!}
          user={context.user!}
          member={member}
          messageTime={getMessageTime(messageTime)}
          options={{ isSocket: true, asReader: false, isTicketManagementMode: false }}
        >
          <MessageBox />
          <ComposerContainer>
            <Composer selectedUser={context.user!} />
          </ComposerContainer>
        </MessageBoxProvider>
      </ReplyMessageProvider>

      <AbstractBottomModal
        snapPoints={({ maxHeight }: { maxHeight: number }) => {
          const sheetHeight = Math.floor(maxHeight / 1.2);
          sheetRefHeight.current = sheetHeight;
          return [sheetHeight, maxHeight];
        }}
        open={isSelectingAssignee}
        onDismiss={handleCloseAssigneeModal}
      >
        {_renderSheetContent()}
      </AbstractBottomModal>
      <ResolveDrawer />
    </Container>
  );
};

type IResolveBtn = {
  text?: string;
  resolving: boolean;
  canResolveTicketNoWrapUp: boolean;
  canResolveTicketWrapUp: boolean;
  showIcon?: boolean;
  handleInstanceResolve: () => void;
  handleOpenResolveDrawer: (isOpen: boolean) => void;
};

const ResolveBtn = ({
  text,
  resolving,
  canResolveTicketNoWrapUp,
  canResolveTicketWrapUp,
  showIcon,
  handleInstanceResolve,
  handleOpenResolveDrawer,
}: IResolveBtn) => {
  if (resolving) {
    return (
      <PrimaryButton
        className="flex gap-x-1 items-center justify-center p-2 min-w-[36px]"
        style={{
          paddingLeft: showIcon ? 8 : 16,
          paddingRight: showIcon ? 8 : 16,
        }}
        disabled
        icon={<FontAwesomeIcon icon={faSpinnerThird} spin className="text-white" />}
      >
        {text && <Font14G1W600 className="!text-white">{text}</Font14G1W600>}
      </PrimaryButton>
    );
  }
  if (canResolveTicketNoWrapUp) {
    return (
      <PrimaryButton
        className="flex gap-x-1 items-center justify-center p-2 min-w-[36px]"
        style={{
          paddingLeft: showIcon ? 8 : 16,
          paddingRight: showIcon ? 8 : 16,
        }}
        onClick={handleInstanceResolve}
        icon={showIcon ? <FontAwesomeIcon icon={fasCheck} /> : null}
      >
        {text && <Font14G1W600 className="!text-white">{text}</Font14G1W600>}
      </PrimaryButton>
    );
  }
  if (canResolveTicketWrapUp) {
    return (
      <PrimaryButton
        className="flex gap-x-1 items-center justify-center p-2 min-w-[36px]"
        style={{
          paddingLeft: showIcon ? 8 : 16,
          paddingRight: showIcon ? 8 : 16,
        }}
        onClick={() => {
          handleOpenResolveDrawer(true);
        }}
        icon={showIcon ? <FontAwesomeIcon icon={fasCheck} /> : null}
      >
        {text && <Font14G1W600 className="!text-white">{text}</Font14G1W600>}
      </PrimaryButton>
    );
  }

  return (
    <PrimaryButton
      className="flex gap-x-1 items-center justify-center p-2 min-w-[36px]"
      style={{
        paddingLeft: showIcon ? 8 : 16,
        paddingRight: showIcon ? 8 : 16,
      }}
      icon={showIcon ? <FontAwesomeIcon icon={fasCheck} /> : null}
    >
      {text && <Font14G1W600 className="!text-white">{text}</Font14G1W600>}
    </PrimaryButton>
  );
};

type IAdditionBlock = {
  assignedName?: string;
  canChat: boolean;
  resolveHandler: IResolveBtn;
  followUpHandler: {
    followUp: boolean | null;
    onFollowUp: () => void;
  };
  teamFollowUpHanlder: {
    teamFollowUp: boolean | null;
    onTeamFollowUp: () => void;
  };
  canNotDoTeamFollowUp: boolean;
  selectedTicket?: ITicket;
  handleSelectAssignee: () => void;
};

const AddtionBlock = ({
  assignedName,
  resolveHandler,
  canChat,
  followUpHandler,
  teamFollowUpHanlder,
  canNotDoTeamFollowUp,
  selectedTicket,
  handleSelectAssignee,
}: IAdditionBlock) => {
  const t = useTranslation();
  const theme: any = useTheme();

  return (
    <div className="rounded-lg bg-[#F5F5F5] p-[12px] flex flex-col gap-y-[12px]">
      <div className="flex gap-x-[20px] items-center justify-between">
        <div className="flex gap-x-[20px] items-center">
          <IconMove
            isActive={followUpHandler.followUp}
            isLoading={false}
            activeIcon={fasStar}
            inactiveIcon={farStar}
            activeColor={'#FFB400'}
            inActiveColor={theme.GREY_5}
            onClick={followUpHandler.onFollowUp}
          />
          <IconMove
            isActive={teamFollowUpHanlder.teamFollowUp}
            isLoading={false}
            activeIcon={fasFlag}
            inactiveIcon={farFlag}
            activeColor={'#f72c40'}
            inActiveColor={theme.GREY_5}
            isDisabled={canNotDoTeamFollowUp}
            onClick={teamFollowUpHanlder.onTeamFollowUp}
          />
          <div>
            <SlaText ticket={selectedTicket} />
          </div>
          {selectedTicket?.pauseSLA ? (
            <div className="flex gap-x-[4px] items-center">
              <FontAwesomeIcon icon={faPauseCircle} className="text-[#BDBDBD]" />
              <Font10G5W600>{t('livechat.ticket.console.ticket.pause.sla')}</Font10G5W600>
            </div>
          ) : null}
        </div>
        <ResolveBtn
          text={t('resolve.btn')}
          resolving={resolveHandler.resolving}
          canResolveTicketNoWrapUp={resolveHandler.canResolveTicketNoWrapUp}
          canResolveTicketWrapUp={resolveHandler.canResolveTicketWrapUp}
          handleInstanceResolve={resolveHandler.handleInstanceResolve}
          handleOpenResolveDrawer={resolveHandler.handleOpenResolveDrawer}
        />
      </div>
      <div className="w-full flex justify-between">
        <div className="flex flex-col gap-y-1 w-[191px]">
          <div>
            <Font14G1W600>{t('livechat.ticket.console.ticket.assignee')}</Font14G1W600>
          </div>
          <div>
            <SelectBtn
              disabled={!canChat}
              className="!bg-white"
              selectedText={assignedName}
              placeholder={t('assignee.modal.choose.assignee')}
              onClick={handleSelectAssignee}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default EngagementMessage;
