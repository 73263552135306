import dayjs from 'dayjs';

export type ISlaCalculatorParams = {
  startTicketTime: string | number | Date | dayjs.Dayjs | undefined;
  sla: {
    hr: number;
    min: number;
  };
  now?: dayjs.Dayjs;
  pauseSLA?: boolean;
  pauseSLATotalMs?: number;
  pauseSLALatestAt?: string;
};

export const slaCalculator = ({
  startTicketTime,
  sla,
  now = dayjs(),
  pauseSLA,
  pauseSLATotalMs,
  pauseSLALatestAt,
}: ISlaCalculatorParams) => {
  if (!startTicketTime) return 'startTicketTime is required';
  if (sla.hr < 0 || sla.min < 0) return 'sla hr or min should not less than 0';
  // input is
  // startTicket time
  // sla time
  const openTicketTime = dayjs(startTicketTime).unix();
  const overDueTime = dayjs(startTicketTime).add(sla.hr, 'hour').add(sla.min, 'minute');
  const pauseSLATotalMsConverted = pauseSLATotalMs ? pauseSLATotalMs : 0;
  if (pauseSLA) {
    const pauseSLALatestAtTime = dayjs(pauseSLALatestAt).unix();
    const diffTime = pauseSLALatestAtTime - openTicketTime - pauseSLATotalMsConverted;
    const duration = dayjs.duration(diffTime * 1000, 'milliseconds');
    return {
      slaHours: duration.hours() + duration.days() * 24,
      slaMinutes: duration.minutes() < 10 ? `0${duration.minutes()}` : duration.minutes(),
      slaSeconds: duration.seconds() < 10 ? `0${duration.seconds()}` : duration.seconds(),
      isOverdue: overDueTime.isBefore(now),
    };
  }

  // get duration from now - startTicketTime
  const diffTime = now.unix() - openTicketTime - pauseSLATotalMsConverted;
  const duration = dayjs.duration(diffTime * 1000, 'milliseconds');
  return {
    slaHours: duration.hours() + duration.days() * 24,
    slaMinutes: duration.minutes() < 10 ? `0${duration.minutes()}` : duration.minutes(),
    slaSeconds: duration.seconds() < 10 ? `0${duration.seconds()}` : duration.seconds(),
    isOverdue: overDueTime.isBefore(now),
  };
};

export const slaCloseDiff = (startSlatime: string, stopSlatime: string) => {
  const start = dayjs(startSlatime);
  const stop = dayjs(stopSlatime);
  const diff = dayjs.duration(stop.diff(start));
  return {
    hour: diff.hours() + diff.days() * 24,
    minute: diff.minutes(),
  };
};
